export default {
  data() {
    return {
      timeRange: [new Date().getTime() - 3600 * 1000, new Date().getTime()],
      interval: null,
      chartInterval: false
    };
  },

  methods: {
    timeRangeChange() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      this.chartInterval = false;
      this.init();
    },

    intervalChange() {
      if (this.chartInterval) {
        this.intervalMetric();
      } else {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    }
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
};
