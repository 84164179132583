var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"chart-wrapper",staticClass:"chart-wrapper"},[(_vm.data.length > 0 && !_vm.loading)?_c('div',{staticClass:"chart-container"},[(['Line'].includes(_vm.info.chartType))?_c('div',{staticClass:"chart-g2-container"},[_c('g2-chart',{ref:"g2-chart",attrs:{"drawAction":_vm.drawAction,"data":_vm.data}})],1):_vm._e(),(['Text'].includes(_vm.info.chartType))?_c('div',{staticClass:"text-container"},[_c('el-scrollbar',[_c('div',{staticClass:"text-item-container"},_vm._l((_vm.lastData),function(item,index){return _c('div',{key:index,staticClass:"text-item"},[_c('div',{staticClass:"text-item-value",class:{
                danger: Number(item.value) >= Number(_vm.info.chartConfig.dangerValue),
                warning:
                  Number(item.value) >= Number(_vm.info.chartConfig.warningValue) &&
                  Number(item.value) < Number(_vm.info.chartConfig.dangerValue)
              },attrs:{"title":item.value}},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('div',{staticClass:"text-item-type"},[_vm._v(" "+_vm._s(item.legend)+" ")])])}),0)])],1):_vm._e(),(['Bar'].includes(_vm.info.chartType))?_c('div',{staticClass:"bar-container"},[_c('div',{staticClass:"bar-item-container"},_vm._l((_vm.lastData),function(item,index){return _c('div',{key:index,ref:"bar-item",refInFor:true,staticClass:"bar-item",style:({ width: item.width + 'px' })},[_c('div',{staticClass:"bar-item-value"},[_vm._v(_vm._s(item.value))]),_c('div',{staticClass:"bar-chart"},[_c('div',{staticClass:"bar-chart-inner",class:{
                success: item.value <= _vm.info.chartConfig.dangerValue,
                danger: item.value > _vm.info.chartConfig.dangerValue
              },style:({ height: item.percent + '%' })})]),_c('div',{staticClass:"bar-item-type"},[_c('el-tooltip',{attrs:{"effect":"dark","content":item.legend,"placement":"bottom"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('span',[_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.legend),expression:"item.legend",arg:"copy"}],staticClass:"el-icon-document-copy",staticStyle:{"float":"left","margin-right":"6px"},on:{"click":function($event){$event.stopPropagation();}}}),_vm._v(" "+_vm._s(item.legend)+" ")])]),_c('div',[_vm._v(" "+_vm._s(item.legend)+" ")])])],1)])}),0)]):_vm._e()]):_vm._e(),(_vm.data.length < 1 && !_vm.errorMessage)?_c('result',{staticClass:"chart-empty",attrs:{"type":"empty"}}):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"error-message"},[_c('svg-icon',{attrs:{"icon-class":"error"}}),_c('div',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }