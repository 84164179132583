<template>
  <div class="chart-wrapper" v-loading="loading" ref="chart-wrapper">
    <div v-if="data.length > 0 && !loading" class="chart-container">
      <div class="chart-g2-container" v-if="['Line'].includes(info.chartType)">
        <g2-chart :drawAction="drawAction" :data="data" ref="g2-chart" />
      </div>

      <div class="text-container" v-if="['Text'].includes(info.chartType)">
        <el-scrollbar>
          <div class="text-item-container">
            <div class="text-item" v-for="(item, index) in lastData" :key="index">
              <div
                class="text-item-value"
                :title="item.value"
                :class="{
                  danger: Number(item.value) >= Number(info.chartConfig.dangerValue),
                  warning:
                    Number(item.value) >= Number(info.chartConfig.warningValue) &&
                    Number(item.value) < Number(info.chartConfig.dangerValue)
                }"
              >
                {{ item.value }}
              </div>
              <div class="text-item-type">
                {{ item.legend }}
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <div class="bar-container" v-if="['Bar'].includes(info.chartType)">
        <div class="bar-item-container">
          <div
            class="bar-item"
            v-for="(item, index) in lastData"
            :key="index"
            ref="bar-item"
            :style="{ width: item.width + 'px' }"
          >
            <div class="bar-item-value">{{ item.value }}</div>
            <div class="bar-chart">
              <div
                class="bar-chart-inner"
                :style="{ height: item.percent + '%' }"
                :class="{
                  success: item.value <= info.chartConfig.dangerValue,
                  danger: item.value > info.chartConfig.dangerValue
                }"
              ></div>
            </div>
            <div class="bar-item-type">
              <el-tooltip effect="dark" :content="item.legend" placement="bottom">
                <div slot="content">
                  <span>
                    <i
                      class="el-icon-document-copy"
                      v-clipboard:copy="item.legend"
                      @click.stop
                      style="float: left; margin-right: 6px;"
                    ></i>
                    {{ item.legend }}
                  </span>
                </div>

                <div>
                  {{ item.legend }}
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>

    <result type="empty" class="chart-empty" v-if="data.length < 1 && !errorMessage" />

    <div class="error-message" v-if="errorMessage">
      <svg-icon icon-class="error"></svg-icon>
      <div>
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { promQlQuery } from "api/monitor";
import { CHART_CONFIG } from "utils/constant";
import { getStep, formatAxisLabel } from "utils";
import { isObject, last, merge, cloneDeep, max } from "lodash";
import Result from "@/components/Result.vue";
import moment from "moment";

export default {
  components: { Result },

  props: {
    timeRange: Array,
    metricInfo: Object,
    autoRefresh: Boolean
  },

  watch: {
    "metricInfo.chartType"() {
      this.init();
    },

    "metricInfo.cluster"() {
      this.init();
    },

    "metricInfo.chartConfig": {
      handler() {
        this.init();
      },
      deep: true
    },

    panelQLs: {
      handler(newVal, oldVal) {
        if (newVal.length <= oldVal.length) {
          this.init();
        }
      },
      deep: true
    },

    timeRange: {
      handler() {
        this.init();
      },
      deep: true
    },

    autoRefresh(newVal) {
      if (newVal) {
        this.intervalMetric();
      } else {
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    }
  },

  data() {
    return {
      data: [],
      loading: false,
      lastData: [],
      currentTime: "",
      errorMessage: "",
      interval: null,
      legendTrans: []
    };
  },

  methods: {
    formatAxisLabel,

    async promQlQuery(query) {
      let subRangeValue = Number(this.timeRange[1]) - Number(this.timeRange[0]);

      let start = Number((new Date().getTime() - subRangeValue) / 1000);
      let end = Number(new Date().getTime()) / 1000;
      let step = getStep((end - start) * 1000, 150);

      let response = await promQlQuery(
        { vendor: this.vendor, region: this.region, cluster: this.cluster },
        { query, start, end, step },
        {
          "X-Kubestar-Clusterids": this.metricInfo.provider
        }
      );

      if (response.code === 0) {
        let rep = JSON.parse(response.data);
        if (rep.status === "error") throw new Error(rep.error);
        return rep.data.result;
      }
    },

    getLegendKeys(s) {
      const regExpression = /\{\{(.+?)\}\}/g;
      let m;
      let names = [];
      while ((m = regExpression.exec(s))) {
        names.push(m[1]);
      }
      return names;
    },

    async init() {
      let vaild = false;

      for (let item of this.providers) {
        let { cluster, kind, regionID, status } = item;
        if (cluster == this.cluster && kind == this.vendor && regionID == this.region) {
          status == "Deny" ? (vaild = false) : (vaild = true);
          break;
        }
      }

      if (vaild) {
        this.loading = true;
        this.data = await this.chartDataGenerator();
        this.loading = false;

        if (this.info.chartType === "Bar") {
          this.$nextTick(() => {
            this.drawBar();
          });
        }
      } else {
        this.errorMessage = this.$t("monitorClusterDeny", [this.cluster]);
      }
    },

    intervalMetric() {
      this.interval = setInterval(async () => {
        this.data = await this.chartDataGenerator();
        this.$refs["g2-chart"].chart.changeData(this.data);
      }, 5000);
    },

    async chartDataGenerator() {
      let metrics = this.metricInfo.panelQLs;

      //初始化图例
      let chartData = [];
      this.legendTrans = [];

      //promQL收集
      let promises = [];
      metrics.forEach(item => {
        if (item.query) promises.push(this.promQlQuery(item.query));
      });

      try {
        let allQueryData = await Promise.all(promises);

        console.log(allQueryData);

        allQueryData.forEach((data, index) => {
          let legendKeys = this.getLegendKeys(metrics[index].legend);

          console.log(legendKeys);

          data.forEach(item => {
            let type = isObject(item.metric) ? JSON.stringify(item.metric) : item.metric;

            let legend = type;
            if (metrics[index].legend) {
              legend = metrics[index].legend;
              if (isObject(item.metric) && legendKeys.length > 0) {
                legendKeys.forEach(key => {
                  let s = item.metric[key] ? item.metric[key] : "";
                  legend = legend.replace(`{{${key}}}`, s);
                });
              }
            }

            this.legendTrans.push({ name: type + index, legend });

            item.values.forEach(value => {
              if (value) {
                chartData.push({
                  date: value[0] * 1000,
                  value: value[1] == "NaN" ? null : value[1],
                  type: type + index,
                  legend
                });
              }
            });
          });
        });

        if (chartData.length > 0) {
          let lastDate = last(chartData).date;
          this.currentTime = lastDate;
          this.lastData = chartData.filter(item => item.date === lastDate);
        }

        this.errorMessage = null;
      } catch (e) {
        this.errorMessage = e;
      }

      return chartData;
    },

    drawBar() {
      if (this.lastData && this.lastData.length > 0) {
        let width = this.$refs["chart-wrapper"].clientWidth;
        let itemLength = this.lastData.length;
        let itemWidth = ((width - itemLength * 10) / itemLength).toFixed(0);

        let maxValue = max(
          this.lastData.map(item => {
            return Number(item.value);
          })
        );

        this.lastData.forEach(item => {
          this.$set(item, "width", itemWidth);
          this.$set(item, "percent", Math.ceil((item.value / maxValue) * 100));
        });
      }
    },

    drawAction() {
      return chart => {
        chart.legend({
          position: "bottom-left",
          flipPage: true,
          itemName: {
            formatter: type => {
              let target = this.legendTrans.filter(item => item.name === type);
              let result = "";
              if (target.length > 0) result = target[0].legend;

              return result;
            }
          }
        });

        chart.tooltip({
          containerTpl:
            '<div class="g2-tooltip">' +
            '<div class="g2-tooltip-title"></div>' +
            '<ul class="g2-tooltip-list"></ul></div>',
          itemTpl: `<li data-index="{index}" class="g2-tooltip-list-item">
              <span class="list-item-marker" style="background-color:{color};">
              </span>
              <div class="list-item-name">
                {legend}
              </div>
              <div class="list-item-value">{value}</div>
            </li>`,

          domStyles: {
            "g2-tooltip": {
              position: "absolute",
              visibility: "hidden",
              border: "1px solid #efefef",
              backgroundColor: "white",
              color: "#000",
              opacity: "0.8",
              padding: "4px 10px",
              transition: "top 200ms,left 200ms",
              maxHeight: "600px",
              overflowY: "scroll"
            },

            "g2-tooltip-list-item": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexFlow: "row nowrap"
            },

            "g2-tooltip-list": {
              margin: "10px"
            },

            "list-item-marker": {
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "8px"
            },

            "list-item-name": {
              maxWidth: "500px",
              minWidth: "100px",
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "20px"
            },

            "list-item-value": {
              fontWeight: "500"
            }
          },
          shared: true,
          showCrosshairs: true,
          showTitle: true
        });

        chart.scale("date", {
          type: "timeCat",
          // range: [0, 1],
          tickCount: 10,
          formatter(value) {
            return moment(value).format("YYYY-MM-DD, HH:mm:ss.SSS");
          }
        });

        chart.scale("value", {
          type: "linear",
          range: [0, 1],
          nice: true,
          min: 0,
          tickCount: 4
        });

        chart.axis("date", {
          grid: {
            line: {
              style: {
                stroke: "#d9d9d9",
                lineWidth: 1
              }
            }
          },
          label: {
            formatter: value => {
              return this.moment(value, "YYYY-MM-DD, HH:mm:ss.SSS").format("HH:mm:ss");
            }
          }
        });

        chart.axis("value", {
          label: {
            formatter: val => {
              return formatAxisLabel(val);
            }
          }
        });

        if (this.info.chartType === "Line") {
          chart
            .line({
              sortable: true
            })
            .position("date*value")
            .color("type")
            .tooltip("type*value*legend", (type, value, legend) => {
              return {
                name: type,
                value: value,
                legend
              };
            });

          if (this.info.chartConfig.showArea) {
            chart
              .area()
              .position("date*value")
              .color("type");
          }
        }

        chart.render();
      };
    }
  },

  computed: {
    panelQLs() {
      return cloneDeep(this.metricInfo.panelQLs);
    },

    info() {
      return merge({}, { chartConfig: CHART_CONFIG }, this.metricInfo);
    },

    vendor() {
      return this.metricInfo.vendor;
    },

    region() {
      return this.metricInfo.region;
    },

    cluster() {
      return this.metricInfo.cluster;
    },

    providers() {
      return this.$store.state.app.userInfo.providers;
    }
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.chart-wrapper {
  height: 100%;
  position: relative;

  .chart-empty,
  .error-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .error-message {
    color: $color-danger;
    text-align: center;
  }
}

.chart-container {
  height: 100%;
  position: relative;
  @include flex(flex-start);

  .chart-g2-container {
    width: 100%;
    height: 100%;
  }
}

.chart-table-container {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  .table-column-select {
    width: 100%;
    margin-bottom: 4px;
  }

  .el-scrollbar {
    height: calc(100% - 32px);
  }
}

.bar-container {
  width: 100%;
  height: 100%;

  .bar-item-container {
    height: 100%;
    width: 100%;
    @include flex(flex-start, flex-start, nowrap);

    .bar-item {
      width: 80px;
      height: 100%;
      overflow: hidden;
      margin-right: 10px;
      @include flex(flex-start, flex-start, nowrap);
      flex-direction: column;

      .bar-chart {
        flex: 1;
        width: 100%;
        height: 100%;
        position: relative;
        background-color: rgba(0, 0, 0, 0.07);

        .bar-chart-inner {
          position: absolute;
          left: 0;
          bottom: 0;

          width: 100%;
          border-radius: 4px;

          &.success {
            background-color: #67c23a;
          }

          &.danger {
            background-color: #e9422a;
          }
        }
      }

      .bar-item-type,
      .bar-item-value {
        width: 100%;
        @include text-overflow();
        text-align: center;
        font-size: 12px;
      }
    }
  }
}

.text-container {
  height: 100%;
  width: 100%;

  .el-scrollbar {
    height: 100%;
    width: 100%;
  }

  .text-item-container {
    @include flex();
    height: 100%;
    width: 100%;

    .text-item {
      // width: 200px;
      height: 100%;
      width: 100%;
      text-align: center;

      .text-item-type {
        font-size: 12px;
        word-wrap: break-word;
        width: 100%;
      }

      .text-item-value {
        @include text-overflow();
        font-weight: 500;
        font-size: 60px;
        color: $color-main;

        // color: $color-success;

        // &.warning {
        //   color: $color-warn;
        // }

        // &.danger {
        //   color: $color-danger;
        // }
      }
    }
  }
}

.chart-g2-container {
  flex: 1;
}

.el-tooltip__popper {
  max-width: 500px;
}
</style>
